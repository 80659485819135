import { env } from 'src/env.mjs';
import { Brand, EventProps } from './EventProps';
import { getUser } from 'src/hooks/User/useUser';
import { useEffect } from 'react';

import { AnalyticsBrowser } from '@segment/analytics-next';

const analytics = AnalyticsBrowser.load(
    {
        writeKey: env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
        cdnURL: env.NEXT_PUBLIC_SEGMENT_CDN_URL,
    },
    {
        integrations: {
            'Segment.io': {
                apiHost: env.NEXT_PUBLIC_SEGMENT_API_HOST,
            },
        },
    },
);

export const identify = (
    userId: string,
    traits?: { [key: string]: string },
) => {
    analytics.identify(userId, {
        brand: Brand.MOSH,
        ...traits,
    });
};

export const page = (name: string, category = '', properties?: EventProps) => {
    analytics.page(category, name, properties);
};

export const track = (
    eventName: string,
    properties?: EventProps,
    callback?: () => void,
) => {
    analytics.track(
        eventName,
        {
            brand: Brand.MOSH,
            url: window.location.href,
            path: window.location.pathname,
            ...properties,
        },
        callback,
    );
};

export const setAnonymousId = (anonymousId: string) => {
    analytics.setAnonymousId(anonymousId);
};

type AnalyticsProviderProps = {
    children: React.ReactNode;
};

function AnalyticsProvider({ children }: AnalyticsProviderProps) {
    const identify = (userId: string, traits: Record<string, string>) => {
        analytics.identify(userId, traits);
    };

    useEffect(() => {
        async function init() {
            // Not using the hook here so as to not mess with hydration
            const data = await getUser();
            if (!data || !data.id || !data.email) return;

            identify(data.id, {
                email: data.email,
                phone: data.phoneNumber,
            });
        }

        init();
    }, []);

    return <>{children}</>;
}

export default AnalyticsProvider;
